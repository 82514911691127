import React from "react";
import { Div, If, Image, Label } from "../../components";
import { Color, Mask, Utils } from "../../utilities";

import IconApple from "../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../assets/images/icones/icon-googleplay.png";
import IconFacebook from "../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../assets/images/icones/icon-instagram.png";
import IconWhatsapp from "../../assets/images/icones/icon-whatsapp.png";
import LogoMobioh from "../../assets/images/logo-mobioh-branco.webp";
import { EmpresaUtils } from "..";
import Zindex from "../utils/Zindex";
import ContatoWhatsappGenerico from "../whatsapp/ContatoWhatsappGenerico";

const getParametros = (empresa) => {
  let parametros = {};
  if (Utils.isValueValid(empresa)) {
    parametros = { ...empresa.parametros };
  }

  return parametros;
};

const getFormasPagamento = (empresa) => {
  let formasPagamento = {};
  if (Utils.isValueValid(empresa)) {
    formasPagamento = [...empresa.formasPagamentosResumidas];
  }

  return formasPagamento;
};

const renderRodapeViewTable = (props) => {
  let empresa = props.empresa || {};
  let formasDePagamento = Utils.quebrarArray(getFormasPagamento(empresa), 4);

  let parametros = getParametros(empresa);
  return (
    <Div className="d-flex align-items-between justify-content-start align-self-start w-100">
      <Div
        className="d-flex flex-column align-items-start justify-content-start"
        style={{ color: Color.ECOMMERCE.WHITE }}
        col="6"
      >
        <Div inline="start" className="w-100">
          <Image src={parametros.urlLogoEmail} width="100px" />
        </Div>
        <Label
          value={`© 2021 ${Utils.capitalize(empresa.fantasia || "")}`}
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          value="Todos os direitos reservados"
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          value={`CNPJ: ${Mask.getValueMaskCpfCnpj(empresa.cpfCnpj)}`}
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12, color: "white" }}
          value={`${empresa.enderecoCompleto} - CEP: ${empresa.cep}`}
          family="Light"
        />

        <Div margin="1">
          <If
            and
            value1={
              Utils.isValueValid(parametros.urlPlayStore) ||
              Utils.isValueValid(parametros.urlAppStore)
            }
          >
            <Label
              value="Baixe nosso app"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="start">
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: Utils.isViewMobileLarguraPequeno() ? 115 : 125,
                  height: 45,
                  padding: 5,
                  color: "white",
                }}
                inline="center"
              >
                <Image src={IconApple} width="15px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="App Store"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="iOS"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: Utils.isViewMobileLarguraPequeno() ? 115 : 125,
                  height: 45,
                  padding: 5,
                  color: "white",
                  marginLeft: 15,
                }}
                inline="center"
              >
                <Image src={IconGooglePlay} width="15px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="Google Play"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="Android"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
            </Div>
          </If>
          <Div inline="start" marginTop="2" className="w-100">
            <Label
              className="align-self-end"
              value="Desenvolvido por:"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
              family="Bold"
            />
            <Image src={LogoMobioh} width="65px" LogoStockei marginLeft="3" />
          </Div>
        </Div>
      </Div>
      <Div col="6">
        <If and value1={Utils.isValueValid(formasDePagamento)}>
          <Div style={{ color: Color.ECOMMERCE.WHITE }} className="w-100">
            <Label
              margin="1"
              value="Formas de pagamento"
              style={{ fontSize: 13 }}
              family="Bold"
            />
            {Array.from(formasDePagamento).map((formas, index) => {
              return (
                <Div key={index} inline="start">
                  {Array.from(formas).map((forma, index) => {
                    return (
                      <Div margin="2" key={index}>
                        <If and value1={Utils.isValueValid(forma.urlFoto)}>
                          <Image src={forma.urlFoto} width="35px" />
                        </If>
                      </Div>
                    );
                  })}
                </Div>
              );
            })}
          </Div>
        </If>
        <Div inline="start" className="w-100">
          <Div marginLeft="1">
            <Label
              value="Redes sociais"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="between" style={{ zIndex: 1050 }}>
              <a href={parametros.urlFacebook} target="_blank">
                <Image
                  src={IconFacebook}
                  width="40px"
                  height="40px"
                  marginRight="1"
                  handlerClick={() => console.log("aopsdjfas")}
                />
              </a>
              <a href={parametros.urlInstagram} target="_blank">
                <Image
                  src={IconInstagram}
                  width="40px"
                  height="40px"
                  marginLeft="1"
                />
              </a>
            </Div>
          </Div>
          <Div
            className="d-flex flex-column justify-content-start align-items-start"
            marginLeft="3"
            style={{ marginTop: 10 }}
          >
            <Label
              value="Atendimento"
              style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="start">
              <Image
                src={IconWhatsapp}
                width="20px"
                marginBottom="2"
                marginRight="1"
              />
              <Label
                family="Light"
                style={{ fontSize: 15, color: Color.ECOMMERCE.WHITE }}
                value={Mask.maskValuePhone(empresa.telefone)}
              />
            </Div>
            <Label
              family="Light"
              style={{
                lineHeight: "5px",
                fontSize: 12,
                color: Color.ECOMMERCE.WHITE,
              }}
              value={empresa.email || "sac@stockei.com.br"}
            />
          </Div>
        </Div>
        <Div
          marginTop="2"
          className="d-flex flex-column align-self-start text-white w-100"
          margin="1"
        >
          <Label value="Segurança" style={{ fontSize: 13 }} family="Bold" />
          <Div inline="start">
            <Image
              src="https://www.maiitre.com.br/imgs/geral/icones/seguranca/iconegooglelojasegura.svg"
              width="65px"
              style={{
                backgroundColor: "white",
                padding: 5,
                borderRadius: 5,
                height: 45,
              }}
              handlerClick={() => {
                window.open(
                  "https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=maiitre.com.br"
                );
              }}
              pointer
            />
            <Image
              src="https://www.maiitre.com.br/imgs/geral/icones/seguranca/iconeletsencrypt.png"
              width="65px"
              style={{
                marginLeft: 5,
                backgroundColor: "white",
                padding: 5,
                borderRadius: 5,
                height: 45,
              }}
              pointer
            />
            <Image
              src="https://www.maiitre.com.br/imgs/geral/icones/seguranca/iconehttps.webp"
              width="65px"
              style={{
                marginLeft: 5,
                backgroundColor: "white",
                padding: 5,
                borderRadius: 5,
                height: 45,
              }}
              pointer
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const renderRodapeViewMobile = (props) => {
  let empresa = props.empresa || {};
  let formasDePagamento = Utils.quebrarArray(getFormasPagamento(empresa), 4);

  let parametros = getParametros(empresa);

  let aceitaEntregaExpress = Utils.isNotUndefined(parametros.aceitaEntregaExpress) ? parametros.aceitaEntregaExpress : false 

  console.log(aceitaEntregaExpress)

  return (
    <>
      <Div
        className="d-flex flex-column align-items-start justify-content-start"
        style={{ color: Color.ECOMMERCE.WHITE }}
      >
        <Div inline="start" className="w-100">
          <Image src={parametros.urlLogoEmail} width="100px" />
        </Div>
        <Label
          value={`© 2021 ${Utils.capitalize(empresa.fantasia || "")}`}
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          value="Todos os direitos reservados"
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          value={`CNPJ: ${Mask.getValueMaskCpfCnpj(empresa.cpfCnpj)}`}
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12 }}
          family="Light"
        />
        <Label
          margin="1"
          style={{ lineHeight: "12px", fontSize: 12, color: "white" }}
          value={`${empresa.enderecoCompleto} - CEP: ${empresa.cep}`}
          family="Light"
        />
      </Div>

      <If and value1={Utils.isValueValid(formasDePagamento)}>
        <Div style={{ color: Color.ECOMMERCE.WHITE }} className="w-100">
          <Label
            margin="1"
            value="Formas de pagamento"
            style={{ fontSize: 13 }}
            family="Bold"
          />
          {Array.from(formasDePagamento).map((formas, index) => {
            return (
              <Div key={index} inline="start">
                {Array.from(formas).map((forma, index) => {
                  return (
                    <Div margin="2" key={index}>
                      <If and value1={Utils.isValueValid(forma.urlFoto)}>
                        <Image src={forma.urlFoto} width="35px" />
                      </If>
                    </Div>
                  );
                })}
              </Div>
            );
          })}
        </Div>
      </If>
      <Div inline="start" className="w-100">
        <Div marginLeft="1">
          <Label
            value="Redes sociais"
            style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
            family="Bold"
          />
          <Div inline="between">
            <a href={parametros.urlFacebook} target="_blank">
              <Image
                src={IconFacebook}
                width="40px"
                height="40px"
                marginRight="1"
                handlerClick={() => console.log("aopsdjfas")}
              />
            </a>
            <a href={parametros.urlInstagram} target="_blank">
              <Image
                src={IconInstagram}
                width="40px"
                height="40px"
                marginLeft="1"
              />
            </a>
          </Div>
        </Div>
        <Div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{ marginTop: 10, width: "100%" }}
        >
          <Label
            value="Atendimento"
            style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
            family="Bold"
          />
          <Div inline="start">
            <If and  value1={aceitaEntregaExpress} >
              <ContatoWhatsappGenerico
                style={{
                  backgroundColor: "#25D366",
                  borderRadius: "50%",
                  padding: 3,
                  fontSize: 20,
                }}
                color="white"
                empresa={props.empresa}
              />
            </If>
            <Label
              margin="2"
              value={Mask.getValueMaskPhone(parametros.telefoneWhatsapp)}
              family="Light"
              style={{ fontSize: 15, color: Color.ECOMMERCE.WHITE }}
            />
          </Div>
          <Label
            family="Light"
            style={{
              lineHeight: "5px",
              fontSize: 12,
              color: Color.ECOMMERCE.WHITE,
            }}
            value={parametros.emailAtendimento}
          />
        </Div>
      </Div>
      <Div
        marginTop="2"
        className="d-flex flex-column align-self-start text-white w-100"
        margin="1"
      >
        <Label value="Segurança" style={{ fontSize: 13 }} family="Bold" />
        <Div inline="start">
          <Image
            src={`https://${empresa.hostServidor}/imgs/geral/icones/seguranca/iconegooglelojasegura.svg`}
            width="65px"
            style={{
              backgroundColor: "white",
              padding: 5,
              borderRadius: 5,
              height: 45,
            }}
            handlerClick={() => {
              window.open(
                `https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=${empresa.hostServidor}`
              );
            }}
            pointer
          />
          <Image
            src="https://www.maiitre.com.br/imgs/geral/icones/seguranca/iconeletsencrypt.png"
            width="65px"
            style={{
              marginLeft: 5,
              backgroundColor: "white",
              padding: 5,
              borderRadius: 5,
              height: 45,
            }}
            pointer
          />
          <Image
            src="https://www.maiitre.com.br/imgs/geral/icones/seguranca/iconehttps.webp"
            width="65px"
            style={{
              marginLeft: 5,
              backgroundColor: "white",
              padding: 5,
              borderRadius: 5,
              height: 45,
            }}
            pointer
          />
        </Div>
      </Div>

      <Div margin="1">
        <If
          and
          value1={
            Utils.isValueValid(parametros.urlPlayStore) ||
            Utils.isValueValid(parametros.urlAppStore)
          }
        >
          <Label
            value="Baixe nosso app"
            style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
            family="Bold"
          />
          <Div inline="start">
            <Div
              style={{
                backgroundColor: "black",
                border: "1px solid white",
                borderRadius: 5,
                width: Utils.isViewMobileLarguraPequeno() ? 115 : 125,
                height: 45,
                padding: 5,
                color: "white",
              }}
              inline="center"
            >
              <Image src={IconApple} width="15px" paddingBottom="2" />
              <Div
                className="d-flex flex-column align-items-start justify-content-center"
                marginLeft="3"
              >
                <Label
                  value="App Store"
                  family="Bold"
                  style={{ height: 10, fontSize: 12 }}
                />
                <Label
                  value="iOS"
                  family="Light"
                  style={{ height: 10, fontSize: 8, marginTop: 1 }}
                />
              </Div>
            </Div>
            <Div
              style={{
                backgroundColor: "black",
                border: "1px solid white",
                borderRadius: 5,
                width: Utils.isViewMobileLarguraPequeno() ? 115 : 125,
                height: 45,
                padding: 5,
                color: "white",
                marginLeft: 15,
              }}
              inline="center"
            >
              <Image src={IconGooglePlay} width="15px" paddingBottom="2" />
              <Div
                className="d-flex flex-column align-items-start justify-content-center"
                marginLeft="3"
              >
                <Label
                  value="Google Play"
                  family="Bold"
                  style={{ height: 10, fontSize: 12 }}
                />
                <Label
                  value="Android"
                  family="Light"
                  style={{ height: 10, fontSize: 8, marginTop: 1 }}
                />
              </Div>
            </Div>
          </Div>
        </If>
        <Div inline="start" marginTop="2" className="w-100">
          <Label
            className="align-self-end"
            value="Desenvolvido por:"
            style={{ color: Color.ECOMMERCE.WHITE, fontSize: 13 }}
            family="Bold"
          />
          <Image src={LogoMobioh} width="65px" LogoStockei marginLeft="3" />
        </Div>
      </Div>
    </>
  );
};

const RodapeMobile = (props) => {
  let empresa = props.empresa || {};
  let parametros = getParametros(empresa);
  let corRodape = EmpresaUtils.getCorRodape(empresa);
  return (
    <Div
      style={{
        backgroundColor: corRodape,
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingTop: "5%",
        paddingBottom: "5%",
        marginTop: "5%",
        opacity: 0.9,
      }}
      inline="between"
    >
      {Utils.isViewTabletAll()
        ? renderRodapeViewTable(props)
        : renderRodapeViewMobile(props)}

      <Div inline="start" className="w-100" marginTop="3">
        <Label
          value={parametros.obsRodape}
          style={{
            fontSize: 11,
            color: Color.ECOMMERCE.WHITE,
            textAlign: "justify",
          }}
          family="Light"
        />
      </Div>
    </Div>
  );
};

export default RodapeMobile;
