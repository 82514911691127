import React, { Component } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  Image,
  InputDefault,
  Label,
  ModalNotification,
  Notification,
  Row,
  Title,
} from "../../components";
import {
  Color,
  EndPoints,
  Http,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../utilities";

import { ReactComponent as IconValor } from "../../assets/images/icon-valor.svg";
import { ReactComponent as IconEntrega } from "../../assets/images/icon-entrega.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { atualizarCarrinho, setEnderecoEntrega } from "../../redux/actions";

import PedidoLojaWeb from "./PedidoLojaWeb";
import RecebimentoPedidoLojaWeb from "./RecebimentoPedidoLojaWeb";
import TooltipText from "../../components/tooltip/TooltipText";
import UsuarioUtils from "../utils/UsuarioUtils";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import { EmpresaUtils, EntregaRetiradaUtils } from "..";
import AdicionarProdutosCarrinhoLista from "../produtos/listascompras/web/AdicionarProdutosCarrinhoLista";
import EmpresaService from "../../service/EmpresaService";
import TemplateItensCarrinhoNovo from "./TemplateItensCarrinhoNovo";
import EntregaRetirada from "./EntregaRetirada";
import PagamentosNovos from "./PagamentosNovo";
import NovoEnderecoCheckoutMobile from "../cliente/mobile/NovoEnderecoCheckoutMobile";
import moment from "moment";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";
import TemplateItensCarrinhoMobile from "./TemplateItensCarrinhoMobile";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 13,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
  labelExcluirCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
    marginTop: 5,
  },
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containerBotaoCancelar: {
    backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    margin: "30px 0px -20px",
    borderRadius: 4,
  },
  containerContinuar: {
    color: "white",
    padding: 12,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerBottom: {
    position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerBottomMostrarTodos: {
    width: "100%",
  },
  containerLogoStockei: {
    padding: 15,
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  },
};

class ItensCarrinhoNovo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarItensCarrinho: true,
      mostrarEntrega: false,
      mostrarPagamento: false,
      mostrarErroEntregaRetirada: false,
      pedidoEntrega: true,
      pedidoRetirada: false,
      pedidoEntregaOutro: false,
      mostrarTodos: false,
      horariosEntrega: [],
      filialSelecionadaRetirada: {},
      erroContinarPagamento: "",
      pagamentoOnline: true,
      pedido: new PedidoLojaWeb(
        props.carrinho.itens,
        props.carrinho.cabecalho.valorTotal,
        props.carrinho.enderecoEntrega
      ),
      horarioRetirada: {},
      horarioEntrega: {},
      recebimentoPedido: new RecebimentoPedidoLojaWeb(),
      ...this.props.location.state,
      windowPagamentoDebito: null,
      corPrimaria: "black",
      novosParamentros: {
        entregaConsciente: false,
      },
      isEntregaPagamento: false,
    };

    // this.handlerPedido = this.handlerPedido.bind(this);
    this.handlerRecebimento = this.handlerRecebimento.bind(this);
    this.voltarParaItensCarrinho = this.voltarParaItensCarrinho.bind(this);
    this.adicionarEndereco = this.adicionarEndereco.bind(this);
    this.continuarFinalizacaoPedido =
      this.continuarFinalizacaoPedido.bind(this);
  }

  componentDidUpdate(props) {
    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }
  }

  componentDidMount() {
    const { carrinho } = this.props;

    this.limparCupomDesconto(carrinho);

    if (carrinho.cabecalho.pedidoFinalizado) {
      Utils.refreshPage();
      this.props.history.replace("/");
    }

    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }

    let empresa = this.state.empresa;
    let parametrosEmpresa = Utils.isValueValid(empresa)
      ? empresa.parametros
      : {};
    this.setState({
      corPrimaria: parametrosEmpresa.corPrimaria,
      corSecundaria: parametrosEmpresa.corSecundaria,
    });

    this.getNovosParamentros();
  }

  renderMessageError(title, response) {
    let errors = response;
    let mensagem = Utils.isValueValid(response.mensagem)
      ? response.mensagem
      : "";
    let detalhesMensagem = Utils.isValueValid(response.detalhesMensagem)
      ? response.detalhesMensagem
      : "";

    let msgErro = Utils.isValueValid(mensagem)
      ? `${mensagem} ${detalhesMensagem || ""}`
      : errors.detail;

    ModalNotification.renderMessage({
      title,
      body: <Label value={msgErro} />,
      labelButtonCancel: "Fechar",
      handlerCancel: () => {},
    });
  }

  getNovosParamentros() {
    EmpresaService.getEmpresaParams().then(({ status, data }) => {
      if (status) {
        this.setState({
          novosParamentros: data,
        });
      }
    });
  }

  limparCupomDesconto(carrinho) {
    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.props.atualizarCarrinho(carrinho);
  }

  calcularValorTaxaEntrega() {
    let parametros = this.state.parametros;
    let carrinho = this.props.carrinho;
    let isEntrega = this.state.pedidoEntrega || this.state.pedidoEntregaOutro;

    carrinho.tipoEntregaRetirada = isEntrega
      ? EntregaRetiradaUtils.TIPO_ENTREGA
      : EntregaRetiradaUtils.TIPO_RETIRADA;

    CarrinhoUtils.calcularValorTaxaEntrega(
      parametros,
      carrinho,
      this.props.atualizarCarrinho
    );

    this.forceUpdate();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState(
              {
                parametros: data,
              },
              () => this.calcularValorTaxaEntrega()
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  voltar() {
    if (this.state.mostrarItensCarrinho) {
      this.props.history.goBack();
    } else if (this.state.mostrarPagamento) {
      this.setState({
        mostrarPagamento: false,
        mostrarEntrega: true,
      });
    } else if (this.state.mostrarEntrega) {
      this.setState({
        mostrarItensCarrinho: true,
        mostrarEntrega: false,
      });
    }
  }

  getEntrega() {
    let parametros = this.state.parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  getRetirada() {
    let { situacao, mensagem } = this.getEntrega();
    return {
      taxaEntrega: 0,
      situacao,
      mensagem,
      ...this.state.filialSelecionadaRetirada,
    };
  }

  renderViewPedidoFinalizado(pedido) {
    this.props.history.push("/finalizacao", {
      pedido,
      logoEmpresa: this.state.logoEmpresa,
    });
  }

  sendPedidoTipoCredito(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa);
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = false;
              carrinho.pagamentoOnline = data;
              this.props.atualizarCarrinho(carrinho);
              this.renderMessageError("Falha ao processar o pagamento", data);
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        } else {
          this.renderMessageError("Falha ao processar o pedido", data);
        }
      })
      .catch((error) => console.log(error));
  }

  renderViewAutenticacaoDebito({ urlAutenticacaoPagamento }) {
    let windowPagamentoDebito = this.state.windowPagamentoDebito;
    windowPagamentoDebito = window.open(
      urlAutenticacaoPagamento,
      "Informe os dados do cartão",
      `height=${window.innerHeight},width=${window.innerWidth}`
    );
    this.setState({ windowPagamentoDebito });
    /**
     * ModalNotification.render(
      "Informe os dados do cartão",
      <Div inline="center" className="w-100">
        <iframe src={urlAutenticacaoPagamento} height="400" width="100%" />
      </Div>
    );
     */
  }

  sendPedidoTipoDebito(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa);
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              if (data.posicao === "FALHA_PGTO_ON_LINE") {
                this.renderMessageError("Finalização pedido", data);
              } else if (data.posicao === "AGUARDANDO_PGTO_ON_LINE") {
                this.renderViewAutenticacaoDebito(data);
              }
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  renderMensagemFalhaFinalizacaoPedido(msg) {
    ModalNotification.renderMessage({
      title: "Falha ao finalizar o pedido",
      body: <Label value={msg} />,
      handlerCancel: () => {},
    });
  }

  sendPedidoOutrosPagamentos(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa);
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.renderMensagemFalhaFinalizacaoPedido(data.mensagem);
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () => this.renderViewPedidoFinalizado(pedido)
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  finalizarPedido() {
    let pedido = this.getPedidoParaCadastro();
    let empresa = this.props.empresa.fantasia;
    const { tipo } = pedido.recebimento;

    if (tipo === "DEBITO") {
      this.sendPedidoTipoDebito(pedido, empresa);
    } else if (tipo === "CREDITO") {
      this.sendPedidoTipoCredito(pedido, empresa);
    } else {
      this.sendPedidoOutrosPagamentos(pedido, empresa);
    }
  }

  removerCupomDesconto() {
    let carrinho = this.props.carrinho;

    if (carrinho.cupomAplicado) {
      carrinho.cabecalho.valorDesconto =
        Number(carrinho.cabecalho.valorDesconto) - carrinho.cupom.valorTotal;
    }

    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.setState({ codigoCupomDesconto: "" }, () => {
      this.props.atualizarCarrinho(carrinho);
    });
  }

  aplicarCupomDesconto(finalizar = false) {
    let pedido = this.getPedidoParaCadastro();
    let carrinho = this.props.carrinho;

    pedido.codigoCupomDesconto =
      this.state.codigoCupomDesconto || carrinho.cupom.codigo;
    Http.post(EndPoints.CUPOM_DESCONTO_VALIDAR, pedido)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            if (!carrinho.cupomAplicado) {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) + data.valorTotal;
              Notification.success("Cupom de desconto aplicado com sucesso!");
            } else {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
            }
          } else {
            Notification.danger(data.mensagem);
            if (carrinho.cupomAplicado) {
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) -
                carrinho.cupom.valorTotal;
            }

            carrinho.cupomAplicado = false;
            carrinho.cupom = null;
            this.setState({ codigoCupomDesconto: "" });
          }

          this.props.atualizarCarrinho(carrinho);
          this.forceUpdate();

          if (finalizar) {
            this.finalizarPedido();
          }
        } else {
          Notification.danger(
            "Falha ao aplicar o cupom de desconto, verifique a conexão com a internet"
          );

          if (finalizar) {
            ModalNotification.confirmarComHandlerCancel(
              "Finalizar pedido",
              "O cupom de desconto aplicado ficou indisponível, deseja finalizar o pedido ?",
              () => this.finalizarPedido(),
              () => {}
            );
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  getValorDescontoVenda() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    let valorDescontoTaxaEntrega = Number(
      cabecalho.valorDescontoTaxaEntrega || 0
    );
    let valorDescontoTaxaServico = Number(
      cabecalho.valorDescontoTaxaServico || 0
    );

    let desconto = valorDescontoTaxaEntrega + valorDescontoTaxaServico;

    if (carrinho.cupomAplicado) {
      desconto = desconto + Number(carrinho.cupom.valorTotal);
    } else {
      desconto = Number(cabecalho.valorDesconto);
    }

    return desconto;
  }

  getDadosCupomDesconto() {
    let { carrinho } = this.props;
    let dados = { valorDescontoCupom: 0 };

    if (carrinho.cupomAplicado) {
      dados.valorDescontoCupom = Number(carrinho.cupom.valorTotal);
      dados.idCupom = carrinho.cupom.idCupom;
    }

    return dados;
  }

  getValorDescontoTaxaEntrega() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    return Number(cabecalho.valorDescontoTaxaEntrega);
  }

  renderResumoDaCompra() {
    const { cabecalho, cupomAplicado, cupom } = this.props.carrinho;

    const { pedido, parametros, corPrimaria, novosParamentros } = this.state;
    let entrega =
      this.state.pedidoEntrega || this.state.pedidoEntregaOutro
        ? this.getEntrega()
        : this.getRetirada();

    return (
      <Div
        inline="center"
        className="align-self-start"
        // responsive="4"
        marginTop="3"
        paddingBottom="5"
      >
        <Label
          value="Resumo da compra"
          style={styles.labelResumoCompra}
          family="SemiBold"
        />

        <Div className="w-100">
          <Div
            className="resumo-compra"
            style={{
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Subtotal do carrinho"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTotal)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconEntrega style={{ width: "30px", margin: 2 }} />
              <Div className="d-flex flex-column justify-content-start">
                <Title
                  value="Taxa de entrega"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTaxaEntrega)}
                  type="h6"
                  style={
                    cabecalho.atingiuEntregaGratis
                      ? styles.taxaEntregaGratis
                      : styles.labelBlue
                  }
                  family="Bold"
                />
              </Div>
            </Div>

            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor taxa de serviço"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(parametros.valorTaxaServico || 0)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Desconto"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(this.getValorDescontoVenda() || 0)}
                  type="h6"
                  style={{ ...styles.labelRed, color: corPrimaria }}
                  family="Bold"
                />
              </Div>
              {Utils.isValueValid(cupom) && cupomAplicado && (
                <TooltipText
                  direction="top"
                  title={`Cupom de desconto aplicado: ${Mask.formatValueBr(
                    cupom.valorTotal
                  )}`}
                  id={Utils.uuidString()}
                >
                  <Label
                    value={`${cupom.codigo} aplicado`}
                    family="Bold"
                    marginLeft="4"
                    style={{
                      fontSize: 12,
                      color: Color.ECOMMERCE.WHITE,
                      backgroundColor: Color.ECOMMERCE.LARANJA,
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                </TooltipText>
              )}
            </Div>
            <Div inline="start" className="border-top ">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor total do pedido"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(
                    Number(cabecalho.valorTotal) +
                      Number(cabecalho.valorTaxaEntrega || 0) +
                      Number(parametros.valorTaxaServico) -
                      this.getValorDescontoVenda()
                  )}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
          </Div>

          <If
            and
            value1={entrega.posicao === "SUCESSO"}
            value2={parametros.valorMinimoPedidoFreteGratis > 0}
          >
            <Label
              family="Bold"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              value={`Entrega grátis à partir de: ${Mask.formatValueBr(
                parametros.valorMinimoPedidoFreteGratis
              )}`}
            />
          </If>
          <If and value1={entrega.situacao === "ERRO"}>
            <Label
              pointer
              value={`${entrega.mensagem} Clique aqui para atualizar o endereço`}
              family="Bold"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              handlerClick={() => this.props.history.push(Routes.CLIENTE)}
            />
          </If>

          <Div
            pointer
            inline="center"
            handlerClick={() => {
              if (!this.state.isEntregaPagamento) {
                this.setState({ isEntregaPagamento: true });
              }
              if (this.state.isEntregaPagamento) {
                console.log("teste");
                this.continuarFinalizacaoPedido();
              }
            }}
            style={{
              ...styles.containerBotaoCancelar,
              backgroundColor: corPrimaria,
            }}
          >
            <Label
              value={
                !this.state.isEntregaPagamento
                  ? "Ir Para Pagamentos"
                  : "Finalizar Pedido"
              }
              marginTop="2"
              style={{ fontSize: 18 }}
              family="SemiBold"
              pointer
            />
          </Div>
        </Div>
      </Div>
    );
  }

  renderTemplateOutroEndereco() {
    ModalNotification.render(
      "Outro local de entrega",
      <NovoEnderecoCheckoutMobile
        labelBotaoConfirmar="Adicionar endereço"
        handlerOutroEndereco={(endereco) => {
          this.setState({ outroEnderecoEntrega: endereco });
        }}
        corPrimaria={this.props.corPrimaria}
        renderAgain={() => this.renderTemplateOutroEndereco()}
        handlerUpdate={() =>
          this.props.handlerChangeTipoEntrega(
            EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
          )
        }
      />
    );
  }

  handlerRecebimento(recebimentoPedido) {
    this.setState({ recebimentoPedido });
  }

  validarItensCheckout() {
    const { carrinho } = this.props;
    const { parametros } = this.state;
    let valorMaximoPedido = Number(parametros.valorMaximoPedido);
    let valorTotal = Number(carrinho.cabecalho.valorTotal);

    let valorEntrega = Number(parametros.valorMinimoPedidoEntrega);
    let valorRetirada = Number(parametros.valorMinimoPedidoRetirada);

    let valorMinimo =
      valorEntrega > valorRetirada ? valorRetirada : valorEntrega;

    if (valorMaximoPedido >= valorTotal && valorMinimo <= valorTotal) {
      this.forceUpdate();
    } else if (valorMaximoPedido < valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido atingiu o valor limite permitido"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor máximo para o pedido: ${Mask.formatValueBr(
              valorMaximoPedido
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    } else if (valorMinimo > valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido não possui o valor mínimo necessário"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor mínimo para o pedido: ${Mask.formatValueBr(
              valorMinimo
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    }
  }

  renderAdicionarProdutoLista(listas) {
    const { itens } = this.props.carrinho;
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutosCarrinhoLista
          listas={listas}
          produtos={itens}
          empresa={this.props.empresa}
        />
      ),
    });
  }

  adicionarEndereco(tipo) {
    this.setState(
      {
        pedidoEntrega: tipo === EntregaRetiradaUtils.TIPO_ENTREGA,
        pedidoRetirada: tipo === EntregaRetiradaUtils.TIPO_RETIRADA,
        pedidoEntregaOutro: tipo === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO,
      },
      () => this.calcularValorTaxaEntrega()
    );
    if (tipo === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO) {
      this.renderTemplateOutroEndereco();
    }
  }

  voltarParaItensCarrinho() {
    this.setState({
      isEntregaPagamento: false,
    });
  }

  renderMessage(message) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value={message}
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => {},
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  validarDadosPagamentoInformado() {
    let erro = "";

    let recebimento = this.state.recebimentoPedido;
    recebimento.tipo = "DEBITO"
    if (this.state.pagamentoOnline) {
      if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
        if (
          !Utils.isValueValid(recebimento.anoValidadeCartao) ||
          !Utils.isValueValid(recebimento.mesValidadeCartao)
        ) {
          erro = "Informe a data de validade do cartão!";
        }

        if (String(recebimento.numeroCartao).length < 16) {
          erro =
            "Número de cartão inválido, verifique se todos os números foram inseridos";
        }

        if (
          Utils.isValueValid(recebimento.anoValidadeCartao) &&
          String(recebimento.anoValidadeCartao).length < 4
        ) {
          erro = "Informe o ano da data de validade com 4 dígitos";
        }

        if (!Utils.isValueValid(recebimento.codigoSegurancaCartao)) {
          erro = "Informe o código de seguraça do cartão!";
        }
        if (!Utils.isValueValid(recebimento.titularCartao)) {
          erro = "Informe o titular do cartão!";
        }
        if (!Utils.isValueValid(recebimento.numeroCartao)) {
          erro = "Informe o número do cartão!";
        }
        if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
          erro = "Informe a bandeira do cartão!";
        }
        if (
          recebimento.tipo === "CREDITO" &&
          !Utils.isValueValid(recebimento.quantidadeParcelas)
        ) {
          erro = "Informe a quantidade de parcelas!";
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    } else {
      if (
        recebimento.tipo === "CREDITO" ||
        recebimento.tipo === "DEBITO" ||
        recebimento.tipo === "DINHEIRO"
      ) {
        if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
          if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
            erro = "Selecione a bandeira do cartão!";
          }
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    }

    if (Utils.isValueValid(erro)) {
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  validarDadosEntregaRetirada() {
    let erro = "";

    if (!this.state.novosParamentros.diasPrevisaoEntrega > 0) {
      if (this.state.pedidoEntrega) {
        if (!Utils.isValueValid(this.state.horarioEntrega)) {
          erro = "Selecione o horário de entrega";
        }
      } else {
        if (!Utils.isValueValid(this.state.horarioRetirada)) {
          erro = "Selecione o horário de retirada";
        }
      }
    }

    if (Utils.isValueValid(erro)) {
      this.setState({
        mostrarEntrega: true,
        mostrarItensCarrinho: false,
        mostrarPagamento: false,
      });
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  getDadosRecebimento() {
    let recebimento = this.state.recebimentoPedido;
    let isOnline = this.state.pagamentoOnline;

    if (
      isOnline &&
      (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO")
    ) {
      return {
        recebimento: {
          ...recebimento,
          dataValidadeCartao: `${recebimento.mesValidadeCartao}/${recebimento.anoValidadeCartao}`,
        },
      };
    } else {
      return { recebimento };
    }
  }

  getTipoPagamento() {
    return this.state.pagamentoOnline ? "ON_LINE" : "OFF_LINE";
  }

  getDadosEntrega() {
    const {
      pedidoEntrega,
      pedidoRetirada,
      pedidoEntregaOutro,
      horarioEntrega,
      horarioRetirada,
    } = this.state;
    let isEntrega = pedidoEntrega || pedidoEntregaOutro;

    let horario = isEntrega ? horarioEntrega : horarioRetirada;

    let entrega = this.getEntrega();
    let retirada = this.getRetirada();

    if (!Utils.isNotObjectEmpty(horario)) {
      horario = this.state.parametros.horarios[0];
    }

    return {
      dataEntrega: moment(horario.dataEntrega, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      idEmpresaHorarioEntrega: horario.idEmpresaHorarioEntrega,
      tipoEntrega: isEntrega
        ? EntregaRetiradaUtils.TIPO_ENTREGA
        : EntregaRetiradaUtils.TIPO_RETIRADA,
      valorTaxaEntrega: isEntrega ? entrega.taxaEntrega || 0 : 0,
      idFilial: pedidoRetirada ? retirada.idFilial : entrega.idFilial,
    };
  }

  getPedidoParaCadastro() {
    const {
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
      obs,
      levarTrocoPara,
    } = this.state.pedido;
    let pedido = CarrinhoUtils.getCarrinho();
    let parametros = this.state.parametros;
    let newLevarTrocoPara = levarTrocoPara ? levarTrocoPara : "";
    let newObs = obs ? obs : "";

    return {
      obs: newObs,
      levarTrocoPara: newLevarTrocoPara,
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
      valorTotal: pedido.cabecalho.valorTotal,
      ...pedido,
      ...this.getDadosRecebimento(),
      ...this.getDadosEntrega(),
      ...CarrinhoUtils.getItensParaCadastro(pedido.itens),
      tipoPagamento: this.getTipoPagamento(),
      valorTaxaServico: parametros.valorTaxaServico || 0,
      ...this.getDadosCupomDesconto(),
      valorDescontoTaxaEntrega: this.getValorDescontoTaxaEntrega(),
      valorTaxaCartao: 0,
      valorDescontoTaxaServico: 0,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
    };
  }

  reenviarNovoPagamento() {
    let pedido = this.getPedidoParaCadastro();

    let recebimento = {
      ...this.getDadosRecebimento().recebimento,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
      tipoPagamentoPedido: this.getTipoPagamento(),
    };

    Http.post("/pedido/novopagamento", recebimento)
      .then(({ status, data }) => {
        if (status) {
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              if (
                data.posicao === "AGUARDANDO_PGTO_ON_LINE" &&
                recebimento.tipo === "DEBITO"
              ) {
                this.renderViewAutenticacaoDebito(data);
              } else {
                this.renderMensagemFinalizacaoPedido(data);
                let carrinho = this.props.carrinho;
                carrinho.erroPagamento = true;
                this.props.atualizarCarrinho(carrinho);
              }
            } else {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = false;
              this.props.atualizarCarrinho(carrinho);
              ModalNotification.removeModal();
              this.props.history.push("/finalizacao", {
                pedido,
              });
            }
          } else {
            this.renderMessageError("Erro ao finalizar pedido", data);
          }
        }
      })
      .catch((erro) => {
        console.log(erro);

        this.renderMessageError("Erro ao finalizar pedido", erro);
      });
  }

  continuarFinalizacaoPedido() {
    if (this.state.isEntregaPagamento) {
      if (
        this.validarDadosPagamentoInformado() &&
        this.validarDadosEntregaRetirada()
      ) {
        if (this.props.carrinho.erroPagamento) {
          this.reenviarNovoPagamento();
        } else {
          if (Utils.isValueValid(this.state.codigoCupomDesconto)) {
            this.aplicarCupomDesconto(true);
          } else {
            this.finalizarPedido();
          }
        }
      }
    }
  }

  render() {
    let corPrimaria = this.state.corPrimaria;
    let logoEmpresa = EmpresaUtils.getLogoEmpresa(this.state.empresa);

    let entrega =
      this.state.pedidoEntrega || this.state.pedidoEntregaOutro
        ? this.getEntrega()
        : this.getRetirada();

    return (
      <Div
        column
        className="w-100"
        style={{
          // maxWidth: "1170px",
          marginTop: !this.props.mobile && -50,
        }}
      >
        <Div
          className="w-100"
          inline="between"
          style={{ position: "absolute", top: 15 }}
        >
          <Div
            inline="center"
            handlerClick={() => this.props.history.replace("/")}
            pointer
            paddingLeft="3"
          >
            <I
              icon={Type.ICON.ARROW_ANGLE_LEFT}
              style={{
                color: Color.ECOMMERCE.CINZA,
                fontSize: 30,
                //marginTop: 3,
                marginRight: 10,
                marginLeft: 10,
              }}
              pointer
            />
            <Image src={logoEmpresa} width="120px" margin="2" />
          </Div>
        </Div>
        <Row
          col="12"
          // inline="between"
          style={{
            display: "flex",
            alignItems: "flex-start !important",
            justifyContent: "space-around",
            marginTop: this.props.mobile && 100
          }}
        >
          <If and value1={!this.state.isEntregaPagamento}>
            <Div responsive="8" style={{ minHeight: "400px" }}>
              <Row
                col="12"
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  fontSize: "18px",
                  padding: "5px 20px",
                  border: "1px solid #dee2e6",
                  borderBottom: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                family="Bold"
              >
                <Div >
                  <Label
                    value="PRODUTOS"
                    style={styles.labelResumoCompra}
                    family="SemiBold"
                    marginBottom="0"
                  />
                </Div>
                <Div>
                  <Checkbutton
                    corPrimaria={Color.EMPRESA.PRIMARIA}
                    checked={true}
                    title="Salvar Lista de Compras"
                    onClick={() => {
                      let listas = JSON.parse(
                        JSON.stringify(this.props.listasDeCompra)
                      );
                      Array.from(listas).forEach((lista) => {
                        Array.from(lista.itens).forEach((p) => {
                          p.valorSubTotalItem =
                            Number(p.precoVenda) * Number(p.quantidade);
                        });
                      });

                      this.renderAdicionarProdutoLista(listas);
                    }}
                    style={{
                      //  margin: "10px 15px 10px 0",
                      width: "230px",
                      height: "45px",
                      fontSize: "14px",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #dee2e6",
                    }}
                    Icon={
                      <I
                        pointer
                        icon={Type.ICON.SHOOPPING_BAG}
                        style={{
                          color: Color.ECOMMERCE.WHITE,
                          fontSize: 18,
                          marginRight: "10px",
                        }}
                      />
                    }
                  />
                </Div>
              </Row>

              <Div col="12" className="border" style={{ padding: this.props.mobile ? "10px 0px" : '10px 20px' }}>
                <If and value1={this.props.mobile}>
                  <TemplateItensCarrinhoMobile
                    {...this.props}
                    mostrarTodos={this.state.mostrarTodos}
                    parametros={this.state.parametros}
                    handlerAddItem={() => this.validarItensCheckout()}

                  />
                </If>
                <If and value1={!this.props.mobile}>
                  <TemplateItensCarrinhoNovo
                    {...this.props}
                    mostrarTodos={this.state.mostrarTodos}
                    parametros={this.state.parametros}
                    corPrimaria={corPrimaria}
                    handlerAddItem={() => this.validarItensCheckout()}
                  />
                </If>
              </Div>
              <Div col="12" inline="between" padding="0" marginTop="3" marginBottom={this.props.mobile ? "3" :  "0"}>
                <Div
                  inline="center"
                  handlerClick={() => this.props.history.replace("/")}
                  pointer
                  paddingLeft="0"
                >
                  <I
                    icon={Type.ICON.ARROW_ANGLE_LEFT}
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 20,
                      marginTop: 3,
                      marginRight: 10,
                      marginLeft: 0,
                    }}
                    pointer
                  />
                  <Label
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                    value="Voltar"
                    pointer
                  />
                </Div>

                <Div
                  inline="center"
                  handlerClick={() =>
                    this.setState({ isEntregaPagamento: true })
                  }
                  pointer
                  paddingLeft="0"
                >
                  <Label
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                    value="Continuar"
                    pointer
                  />

                  <I
                    icon={Type.ICON.ARROW_ANGLE_RIGHT}
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 20,
                      marginTop: 3,
                      marginRight: 0,
                      marginLeft: 10,
                    }}
                    pointer
                  />
                </Div>
              </Div>
            </Div>
          </If>

          <If and value1={this.state.isEntregaPagamento}>
            <Div responsive="8" style={{ minHeight: "400px" }}>
              <EntregaRetirada
                handlerEnderecoSelecionado={(endereco) => {
                  this.setState(
                    {
                      pedido: {
                        ...this.state.pedido,
                        idClienteEndereco: endereco.idClienteEndereco,
                      },
                    },
                    () => {
                      let carrinho = {
                        ...this.props.carrinho,
                        enderecoEntrega: endereco,
                      };

                      this.props.setEnderecoEntrega(carrinho);
                    }
                  );
                }}
                idClienteEnderecoSelecionado={
                  this.state.pedido.idClienteEndereco
                }
                filialSelecionadaRetirada={this.state.filialSelecionadaRetirada}
                handlerFilialSelecionada={(filial) =>
                  this.setState({
                    filialSelecionadaRetirada: filial,
                  })
                }
                adicionarEndereco={this.adicionarEndereco}
                parametros={this.state.parametros}
                handlerHorarioSelecionado={(horario) =>
                  this.setState({ horarioEntrega: horario })
                }
                mobile={this.props.mobile}
              />
              <PagamentosNovos
                mobile={this.props.mobile}
                formasPagamentos={this.state.parametros.formasPagamentos}
                quantidadeMaximaParcelas={
                  this.state.novosParamentros.quantidadeMaximaParcelas
                }
                empresa={this.state.empresa}
                parametros={this.state.parametros}
                pedido={this.state.pedido}
                recebimentoPedido={this.state.recebimentoPedido}
                handlerRecebimento={this.handlerRecebimento}
                carrinho={this.props.carrinho}
                continuarFinalizacaoPedido={this.continuarFinalizacaoPedido}
                handlerSucessoPagamento={(data) => {
                  let pedido = this.getPedidoParaCadastro();
                  ModalNotification.removeModal();
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  });
                }}
                handlerErrorPagamento={(data) => {
                  this.renderMessageError("Falha ao realizar pagamento", data);
                }}
                windowPagamentoDebito={this.state.windowPagamentoDebito}
                handlerInitPagamento={() =>
                  this.setState({
                    recebimentoPedido: new RecebimentoPedidoLojaWeb(),
                  })
                }
                handlerTipoPagamento={(pagamentoOnline) =>
                  this.setState({ pagamentoOnline })
                }
              />

              <Div
                col="12"
                inline="between"
                padding="0"
                marginTop="3"
                marginBottom="3"
              >
                <Div
                  inline="center"
                  handlerClick={() => this.voltarParaItensCarrinho()}
                  pointer
                  paddingLeft="0"
                >
                  <I
                    icon={Type.ICON.ARROW_ANGLE_LEFT}
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 20,
                      marginTop: 3,
                      marginRight: 10,
                      marginLeft: 0,
                    }}
                    pointer
                  />
                  <Label
                    style={{
                      color: Color.RGBA.AZUL,
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                    value="Voltar"
                    pointer
                  />
                </Div>
              </Div>
            </Div>
          </If>

          <Div
            responsive="4"
            marginBottom="4"
            style={{
              maxHeight: entrega.situacao === "ERRO" ? "590px" : 490,
              maxWidth: "380px",
              backgroundColor: "rgb(231, 232, 234)",
              borderRadius: "8px",
              marginBottom: this.props.mobile ? "37px !important" : "0"
            }}
          >
            {this.renderResumoDaCompra()}
          </Div>
        </Row>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setEnderecoEntrega }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItensCarrinhoNovo);
